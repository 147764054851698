import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Common actions are frequently used actions that appear across different components and workflows. For platform consistency, these actions should only be applied in the ways described below.`}</p>
    </PageDescription>
    <h2 {...{
      "id": "regressive-actions"
    }}>{`Regressive actions`}</h2>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Cancel</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Clear</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Close</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Delete</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Errors</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Remove</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Reset</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "cancel"
    }}>{`Cancel`}</h3>
    <p>{`Cancel stops the current action and closes the component or item. Warn the user of any negative consequences of stopping the action from progressing, such as data corruption or data loss.`}</p>
    <p>{`Use a secondary button or a link for cancel actions.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "55.434782608695656%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB1klEQVQoz62TzY7SUBSASwdqjI4kBAz1DTSBiQlLE+Po0qUrjW4wMHHDygfwAZzoozBjhyY8A7+TlL8UnAQJrdRp6aXtEHqPp3cKYZSdNvlyzj259+tp7inHcdwJchrGf2Ht2CSn0Wj0WywWYwT5Nuv6LrYdXCQSYYt4PH5WrVa1er1u1Wq1y1arZa5pNpsm1hmNRoOB+WW73bZkWdaSyWQFHWXmCoXlVCpV6fV6V5qmwXg8Bl3XGdPpFCzLguChlILv+7BarWC5XLIa7r0SRVH+Sxi8BbtZDIdD6Pf7/mAwoAGYU1VVGd8vLujP2Ywaxi9qmqa/WDigqsNFOp2Wd3aIn+KgABRFoZ1OB7DjDR1FgW63C5PJBAxjBvP5nHqeB6PRyBHFHcJEIlHB7hzXdYPNfnDAtu0NbI0QYlNiz6llmb7rOjDCMzc6DG+oLAhCJZ/Pe6VSCQqFAhSLReQIjgrvIf/hI7w8JvDiC8CzzwCHx9fx+VeAJ58MT7j3gAm5LeEJz/Nn2WzWyOVyTiaTIZgjB+Qg84g8fPyU7L8+J7F3OuHf/EAm1/Gt7vCvzg3u9n12yxy3JQznUArA2bqJIEi8cFfau7UvRf9gT7gjhaL1cP/fP+U3th+VdIFR8dcAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of cancel in a modal",
        "title": "Example of cancel in a modal",
        "src": "/static/e659501d11d5d164f344abad03898984/fb070/common-action-1.png",
        "srcSet": ["/static/e659501d11d5d164f344abad03898984/d6747/common-action-1.png 288w", "/static/e659501d11d5d164f344abad03898984/09548/common-action-1.png 576w", "/static/e659501d11d5d164f344abad03898984/fb070/common-action-1.png 1152w", "/static/e659501d11d5d164f344abad03898984/c3e47/common-action-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "clear"
    }}>{`Clear`}</h3>
    <p>{`Clear removes data from a field or removes selections. Clear can also delete the contents of a document, such as a log. Typically, the default selection or value is reset for controls that have a default selection or value, such as radio buttons.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`x`}</inlineCode>{` icon on the right side of a field, item, or value.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "58.016304347826086%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABg0lEQVQoz41Ty2rCQBTNBJr+SaG7rkuhv+B/mE277S4/UtvionUt2Rgr7gpGMRqJWNGIr/hMfCQqlOnJkBShRDNwmMU9c+6ZuWc47mhRSrm46yw3JGAngHCM1WolOI4j2LYteJ53eTgcLs6KyrJM/F3X9RtJkgxVVfXRaKSbpqmv1+sQGoRN4NXnzmYzPlIwl8uxYqFQuMvn87TValHLsuhwOKSTyYQBDajruhRNvnzudruNFlQUhRWz2extqVT6qdVqe03T9s1mc28YRgi30+nQRqPx6XN3u120YL1eZ8VqtXo/nU6ZI7wZcxQCV6b+gtNy8IbRgoPBgAT7FfDW7/dTy+UyNZ/PX47wDLyj4VM4wJOThgMSNzaY/GnuYrFgBLi6hoOHXq+XhEtxPB6Lm81GRDMRQ0hiso+oJXxuu92OFoUIH0QhAREDj1/udrsViFbQhAFxUTH1b0TrI4gYf9IlDse9MY+mXCwxOOUQFZJOp0kmkyHFYpEEv+cP4Rnk9J/OL66tZLBBMJtEAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of clear in Multi-select Dropdown and in Search",
        "title": "Example of clear in Multi-select Dropdown and in Search",
        "src": "/static/35da3f4e228bb845ab0b1bce680f573b/fb070/common-action-2.png",
        "srcSet": ["/static/35da3f4e228bb845ab0b1bce680f573b/d6747/common-action-2.png 288w", "/static/35da3f4e228bb845ab0b1bce680f573b/09548/common-action-2.png 576w", "/static/35da3f4e228bb845ab0b1bce680f573b/fb070/common-action-2.png 1152w", "/static/35da3f4e228bb845ab0b1bce680f573b/c3e47/common-action-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "close"
    }}>{`Close`}</h3>
    <p>{`Close terminates the current page, window, or menu. Close is also used to dismiss information, such as notifications.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`close`}</inlineCode>{` icon, which is typically placed on the upper right side of the element. Do not use close in a button.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "39.130434782608695%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAA0ElEQVQoz62STwqCQBjFtRN5hq4X1K4rdAkZayHVMsEw0iwrs3S0LLU/1muUKIiKWfTBY77F4zfvMSMIbwNA4BkuHy+smFqj+dvQ6uyfe39I64bldvWxK5tOQIL9jWx2OfHjK1mFJ8WLzlLhW28v4ndg+wXs6YFqLyk0w8FouoG1CGEvI0QpkF4BP86rhY9dVOECanamHll/luCpApbkQHYD6AElkJ0VrsqDSVqfulHXdKhszimZeTEJE5TVmRQm6ZFQ/NujsHTC/75Dme6z7w6+Qqrj4tVWsAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of close in an inline notification",
        "title": "Example of close in an inline notification",
        "src": "/static/ad273d54ddd19c09f71cae3bf691a1b8/fb070/common-action-3.png",
        "srcSet": ["/static/ad273d54ddd19c09f71cae3bf691a1b8/d6747/common-action-3.png 288w", "/static/ad273d54ddd19c09f71cae3bf691a1b8/09548/common-action-3.png 576w", "/static/ad273d54ddd19c09f71cae3bf691a1b8/fb070/common-action-3.png 1152w", "/static/ad273d54ddd19c09f71cae3bf691a1b8/c3e47/common-action-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "delete"
    }}>{`Delete`}</h3>
    <p>{`Delete destroys an object. Delete actions cannot be easily undone and are typically permanent. The user should be warned of the negative consequences of destroying an object, such as loss of data. Use either the `}<inlineCode parentName="p">{`delete`}</inlineCode>{` or `}<inlineCode parentName="p">{`trash can`}</inlineCode>{` icon, a danger button, or a danger option in a menu. A danger modal is used when a warning is needed to confirm an action.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "111.00543478260869%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsSAAALEgHS3X78AAADbUlEQVQ4y7WUT2wbRRTGd7OxnQYU6uBIboRwLuQATXzKjTOnVhyQ4FSEgggkuUUVSBUHThzohTM1rSjqFZrW6lqlVD00Uv4qkSLlr7N1pCiund31Ovaud9e78/hm47hraC4FRvrpzXye+fxmZ94IgiDcA/db8d9w6tHu3O/q6mojimJAWDuLsIfAF/FBX1/fA1mWi4uLi5X5+XltZWVFX15e1jE+Cz6nks1mi4lEQobHbODVMpwdGBiQNzY2nGKxSKVSiXhUVZV836dwY4wFWrPZDMYHBwfO4OBg7h+G/F/W1tbMfD5POzs7PmC7u7tMURRWKBTYXj7P9hHVoyOmaRozDMO3TJOUvT0zmUzmXprh6uqqtb29TciUbW5u0tbWFp1GaEE8PDwMMj8+PmZ2w6ZnyjPrpYb9/f0yMrEcx6F6vc5M0+ygBo5BvdFgNctiBuY0XJeU/X0reeHCC8PWCc3GYjF5YmLCnpmZocnJSZqamqJp8NX0NH395SQpn3xB+kefU+nSFSpf/pRKl6+Q8eFntPHBx3Yydu7E8MQrOO5ZHP+DdDqtjY2NWSMjI/XR0VEzDd5Lj5rvv3vRfDQwbK7F3zEXe982l3pTPNZXe1PWw3NvaQlRkluGwT3MtrLMRiIRORqN5pBtBz3gvNSdi3Mi0Tb94I3uiCyGPLjh7yF+e0XCHkLqFHzUlCRJQ9j+EO9z0E91Q4uJXUM9ICaIqTBREPYQ/o/WHR4MDw/3/F0bHx+P9KChK53hIcbjcZFHYWlp6Taq4gYHlzozNzd3BzV6G1USaLjYP2P868LCwp319fVfoP3EdfzO481yuXzdtu3Xuauu64KAS9tRqygpsiyrQ2s0GrwyiF963hzbJkPXiXk+VTTd0FU1ERiqqihgsYc5vNKbKPwmFnqolBcaMG3bQ/16yCTQrabbPDQqbhWVUvf9/eeG8SY3rNi2KGCxi9cjwPM8Fxm6tVrNhXkAb9Vq1cV2XNdxXBi61Z1dV7l7zyn+8Sepj58UCrmHgWHx0WNRoFdpT+fJ/u57Yj/8SMo339aeX70WbPng6jVRwBYzyC4Ab1wGmWTCmu95GTxTGbwwgc64RsS5AW7hu1zHX7zGDfF5/ru7R6dmOEEJgzbIQsJBdWjIXKpUKlJ7Lo/lI4lUTXKhU6l8Yqpqwl8z9GZdVYnrgAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of delete in a Modal and Overflow Menu",
        "title": "Example of delete in a Modal and Overflow Menu",
        "src": "/static/a480730388da6cd6c3f008e6f65379cd/fb070/common-action-4.png",
        "srcSet": ["/static/a480730388da6cd6c3f008e6f65379cd/d6747/common-action-4.png 288w", "/static/a480730388da6cd6c3f008e6f65379cd/09548/common-action-4.png 576w", "/static/a480730388da6cd6c3f008e6f65379cd/fb070/common-action-4.png 1152w", "/static/a480730388da6cd6c3f008e6f65379cd/c3e47/common-action-4.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "non-dangerous-deletion-5-of-cases"
    }}>{`Non-dangerous deletion (~5% of cases)`}</h4>
    <p>{`Use when it’s trivial to undo deletion or re-create the item. Delete the item upon click or tap without further warning.`}</p>
    <h4 {...{
      "id": "moderate-danger-deletion-80-of-cases"
    }}>{`Moderate danger deletion (~80% of cases)`}</h4>
    <p>{`Use when an action cannot be undone or the item cannot be recreated easily. This pattern is also useful if you’re deleting more than one thing.`}</p>
    <p>{`Ask for confirmation of the delete, with guidance about what will occur if they delete.`}</p>
    <h4 {...{
      "id": "high-danger-deletion-15-of-cases"
    }}>{`High danger deletion (~15% of cases)`}</h4>
    <p>{`Use when it would be very expensive or time-consuming to recreate an item. Also use if the action deletes a large number of items, or if other important items would be deleted as a result of the action.`}</p>
    <p>{`In addition to presenting a dialog, have the user type the name of the resource they are deleting (manual confirmation).`}</p>
    <h4 {...{
      "id": "post-deletion-flow"
    }}>{`Post-deletion flow`}</h4>
    <p>{`After the user deletes an item, return to the page that lists the item deleted. Animate the removal of the item from the list or page and present a success notification.`}</p>
    <p>{`If the deletion fails, raise a notification to tell the user that deletion failed. Send a second notification on another communication channel, like email, if possible. Animate the item back onto the page if possible.`}</p>
    <h3 {...{
      "id": "errors"
    }}>{`Errors`}</h3>
    <p>{`Errors occur when an action or process does not succeed. Error notifications can occupy full pages, form fields, notifications (toast, inline, etc.), and modals. Ultimately, all should provide context of what happened and a clear path to continue.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "113.45108695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAAsSAAALEgHS3X78AAADgUlEQVQ4y62VXU/bZhTHcYKWqV13E5ogxUBFRyckEBLc7KZTJS62D4Aaab1wXwSTKu0D7AK2kd70BmmTBhO0QqiaNhVBgTGgpEko4s1tx0hEaWpKgmMnsRO/J2lTyPCOH9Ipq1NVVD3ST//nRec85/E5tisqiuZ0OhGl49L5O5vL5frfvLq6+t0CNTc3I21ra7M0NjbWNDU14V1dXTZd1y0sy1o1TUNkMplSkM8rNRk4HzmRbDZbfiMajSLlOO5YOBy+xDDMV8lk8nw6nXbncjk3ZIGAAAbngQuAqxgUMwVUVRUrZnkSApFwQIim6Q0Yh2AvJMtyKRvANnDO8AG1vLcrFwoFrOwGXA1tKIpih6zmE4lEANQvCMIsZPgnXHemhGnAB3xWLIo5QwiENJ/Pf5RKpb6FgD08z3cbCod9J4piTwndwDXgU8MH1Jyl2+1G2t7ebunt7bUZ4+Hh4bc+hjfuS5KETins71fH43EGqp3OaBoH1+Vfw1hLABrwRbGgVlNAMrKDNKjK1kVy7VR4ZwdPShJOcxweicfxaMIggUdYBt9VFVfsxfOaXVn60PChc2V6kfp9FOlm30/HIyurnamtJwQfDBHi462L6lOKUMIGTwl1+xkhP/yLEBaXrojLqzXoGQbum5+hePfeYZVv/VYlhDbXIGCQp7ZJMbpLqjGGVGIxUqFjpMrGSXnryaqw/ndQ3Ah+jnwfPLJUvC/L6Hr5PhSkw9IXDv6pohlmOcnza9lcbhk+CCvQZ6+zBAVbBz37xj4Us4dfjH394BgrCpcTHPe1oqpdkqIAclEPSQlCZ1oUr6YFoc7wgbk5y+TtcbT4bGjYnr2/NKbuRKeESHRC2aUnVTo2mWHjkxmGndRizMSBJN/ZS6Xn9tICelNgbvH7/eUbFNSmR2M/6rnnv+gv8v36y70BfW9/IM/xAxB0IBdP9MP6z7qq3dQP9DPIR9XMV65yONCiw+n88mPHyb7jJ058b62s9MCS5wObzTM4NOTx+f2emdlZzx/T0z/MzM1d997zdho+v47erjBliOO4tfgL+KahoSH1yenTWzCmnA4HVVdXR42MjFALCwvU/Pw85fV6w36fj4UgNwyfQCCAmQIa1tLScuS2gUPKb7S2tv73byEIAuvo6MDq6+sxu92O1dbWYoODg9jU1BQ2NjaGjY+PY5ClkRUqpM/nQxj2LzdknTwpjRI0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Form example with inline notification",
            "title": "Form example with inline notification",
            "src": "/static/cda1826e4cf1266ab805e62922c3bced/fb070/notification-usage-3.png",
            "srcSet": ["/static/cda1826e4cf1266ab805e62922c3bced/d6747/notification-usage-3.png 288w", "/static/cda1826e4cf1266ab805e62922c3bced/09548/notification-usage-3.png 576w", "/static/cda1826e4cf1266ab805e62922c3bced/fb070/notification-usage-3.png 1152w", "/static/cda1826e4cf1266ab805e62922c3bced/c3e47/notification-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <p>{`Consider redirecting the user to a previous state, a support page, or by offering helpful recommendations. Be honest and helpful.`}</p>
    <p>{`Some components, like text input and form field errors, are quite small and require more thoughtful approaches to the space and placement of error handling. Consider inline error notifications for these instances.`}</p>
    <h4 {...{
      "id": "copy-guidelines"
    }}>{`Copy guidelines`}</h4>
    <p>{`Be brief, honest, supportive, and human. Explain what happened and what can the user do to resolve the error.`}</p>
    <p>{`For full-page and large modals, keep error messages no longer than three paragraph lines. For form errors, keep error messages no longer than two lines.`}</p>
    <h3 {...{
      "id": "remove"
    }}>{`Remove`}</h3>
    <p>{`This action removes an object from a list or item; however, the object is not destroyed as a result of the action. Multiple objects can be removed at once.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.69021739130435%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABU0lEQVQoz62SvWrCUBSAT1qTWBqpUGchkME8iQ6iq0NWwTVblzxDVhdbIdAhxSeIP0gcXBIkCZQsajIlmz+bDvbcqFitdGg98HG4557z3XvhAtwg1uv1abHb7f4t3G635wUijaKoPZvNPoMgsJbL5QRPnaxWq9+wscdHWsSx2WwAaydhHMfKfD7/QKG2WCza2PiKvB3yNVooeEdeiMO27f3Ty+Xy98veH/h7MAyTZFmWHyVJytVqtVyj0chOp1PGcRzW87wfuK7L+r7PDgaDdKfTSQSFQgFwD4DjuERYr9fT1Wo1W6lUnhRFSY/H41S/36dx6Cqj0Sil6zrTbDZpMi8IAmAdIJPJHIUPxWIxVyqVnnu9Hm2aJt3tdmkiJetLUEhrmsaqqpoIeZ4HrO+fnc/nz77PcDikDMO4QxmFTRTJl5B9y7KoMAyp4xzWAURRhFvGF4KOB+Cf/YHrAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of Remove in a Structured List",
        "title": "Example of Remove in a Structured List",
        "src": "/static/4f222603c06c6c170823133b1d8a0297/fb070/common-action-5.png",
        "srcSet": ["/static/4f222603c06c6c170823133b1d8a0297/d6747/common-action-5.png 288w", "/static/4f222603c06c6c170823133b1d8a0297/09548/common-action-5.png 576w", "/static/4f222603c06c6c170823133b1d8a0297/fb070/common-action-5.png 1152w", "/static/4f222603c06c6c170823133b1d8a0297/c3e47/common-action-5.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "hierarchy-and-placement"
    }}>{`Hierarchy and placement`}</h4>
    <p>{`Represent remove as a button or `}<inlineCode parentName="p">{`subtract`}</inlineCode>{` icon or glyph. The remove action is rarely the primary action on the page and should not be overly emphasized.`}</p>
    <h4 {...{
      "id": "considerations"
    }}>{`Considerations`}</h4>
    <ul>
      <li parentName="ul">{`What are the implications of the add action for the user? Are there financial, access, or legal considerations?`}</li>
      <li parentName="ul">{`This action can be confused with deleting.`}</li>
      <li parentName="ul">{`A user may not have the correct permissions for this action.`}</li>
      <li parentName="ul">{`Inform the user if the result is permanent.`}</li>
      <li parentName="ul">{`How long will the action take? Seconds, minutes, hours, or days?`}</li>
      <li parentName="ul">{`What should the user do if the removal fails?`}</li>
      <li parentName="ul">{`Is this a single or bulk action?`}</li>
    </ul>
    <h3 {...{
      "id": "reset"
    }}>{`Reset`}</h3>
    <p>{`Reset reverts values back to their last saved state. The last saved state includes the values stored the last time the user clicked or triggered `}<strong parentName="p">{`Apply`}</strong>{`.`}</p>
    <p>{`Reset is typically applied as a link.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "29.891304347826086%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABQElEQVQY023PwUoCQRwG8Fk3NS9bFIZtEia7HQpZJCkyEAzBosgoJAkhQUQIOvQolRcvnetQl+joAyS4h32APe9lFXZh19v0/QeJiAZ+fN/MzgyzjNFocxGsxSXW4Qrrwi2PsF/DcZwF3/d1z/NWkFnYnM1JHr2A3BGbq92+yPr9c6TYfFTipd58KlebozVN0+4QBc55GTgO9XCYvKO/IN/gCkpQERdtrKsis5l0LJNOplOLsqImFXGhYRjLiNh0Os3hglegw5fQhhtoYY3mZ1Bjk8lEGgwG4vc0XV9aVdW9xnUzjsdIw+GXWEeXR6NRlDr9MtDrPuFjln14gCf2Z8iwBgmQ2D8jDMMoXnIOdbiARhAEiZ8NeGERylChbtv2qWmaJ5Zl0fwIDsbj8SEco+8D9bzrulvou+jbszuq9P0bFH/rWSelcTkAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of reset in a filter",
        "title": "Example of reset in a filter",
        "src": "/static/a96e4cc567d41fa91b0c6837e61fa45e/fb070/common-action-6.png",
        "srcSet": ["/static/a96e4cc567d41fa91b0c6837e61fa45e/d6747/common-action-6.png 288w", "/static/a96e4cc567d41fa91b0c6837e61fa45e/09548/common-action-6.png 576w", "/static/a96e4cc567d41fa91b0c6837e61fa45e/fb070/common-action-6.png 1152w", "/static/a96e4cc567d41fa91b0c6837e61fa45e/c3e47/common-action-6.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "progressive-actions"
    }}>{`Progressive actions`}</h2>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Add</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Copy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Edit</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Next</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Refresh</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "add"
    }}>{`Add`}</h3>
    <p>{`Add inserts an existing object to a list, set, or system. For example, adding a document to a folder.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "33.96739130434783%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABQElEQVQoz5WRuU7DQBRF/SkUFFDR8ROk4m+oERJyQUUBAr4jSokQER1FFDteEsVLosR2HG9D8RzN5c2EIFdIWDo+1vWbO16MqqrumVvG/Iu6rjWiqc0kr803W5ivltAeToT5zgwdcWrgH4f8OX9GwJkJnN/tfXwNnNwAR1fy0sjzHL7vf3meR2EYUhAEmvl8TrZtk2VZ2rPZjLOAPHdCH58OPQ+W9DKI6WmwoIf+on3sa18YQghMp1NyHAdcgiiKEMcxuBxchvF4jNFoBC7EcrmE67rwXQdlFmObhCiSSJZphCKNJNPTr1yWJWVZhjRNwd8JTdNoq6dXbDYbjcqLosCWKavmgOx4X8iLaLVaQcHl4J+gvV6vNSpPkkRvojZWqGs1x8iOe4aUEm3bSiKCYrfb/XLIuvd4VtOZkwdz3vsGcPX6FRtcuYEAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of add as a Button with an con in a Data Table",
        "title": "Example of add as a Button with an con in a Data Table",
        "src": "/static/9b9777055d3d822ac1eff7822555bdbc/fb070/common-action-7.png",
        "srcSet": ["/static/9b9777055d3d822ac1eff7822555bdbc/d6747/common-action-7.png 288w", "/static/9b9777055d3d822ac1eff7822555bdbc/09548/common-action-7.png 576w", "/static/9b9777055d3d822ac1eff7822555bdbc/fb070/common-action-7.png 1152w", "/static/9b9777055d3d822ac1eff7822555bdbc/c3e47/common-action-7.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "hierarchy-and-placement-1"
    }}>{`Hierarchy and placement`}</h4>
    <p>{`Represent add with a primary button, button with an icon, `}<inlineCode parentName="p">{`add--glyph`}</inlineCode>{` or `}<inlineCode parentName="p">{`add--outline`}</inlineCode>{` icon.`}</p>
    <p>{`Depending on the relevance of the add action on the page, the emphasis can be high, medium or low. For example, a high emphasis action should use a single prominent button that makes it clear that other buttons have less importance in the hierarchy.`}</p>
    <h4 {...{
      "id": "considerations-1"
    }}>{`Considerations`}</h4>
    <ul>
      <li parentName="ul">{`What are the implications of the add action for the user? Are there financial, access, or legal considerations?`}</li>
      <li parentName="ul">{`A user may not have the correct permissions for this action`}</li>
      <li parentName="ul">{`Inform the user if the action is permanent`}</li>
      <li parentName="ul">{`What timeframe will the action take (seconds, minutes, hours, days)?`}</li>
      <li parentName="ul">{`What should a user do if the addition fails?`}</li>
      <li parentName="ul">{`Is this a single or bulk action?`}</li>
    </ul>
    <h3 {...{
      "id": "copy"
    }}>{`Copy`}</h3>
    <p>{`Copy creates a new identical instance of the selected object(s) in a distinct destination.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`copy`}</inlineCode>{` icon with a confirmation “copied” tooltip appearing post-click.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.298913043478258%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABAUlEQVQY05VQu2qEQBQdlyRDylQmX+EnaOxstxOx8mPyGfEDLNRCXNDKddtgIQQEBRERRHygKDbmzsAmWaz2wOFw7uWeuXcQAnRdRwT1fY/atmXA37BpGqaua4b0hmFgsixj0D+Iooh2mKYJ3QkSigVBeCCG47h92DiOz8uyvK7ryl45zzMLfRa2ox42fNu27UXTtA9Zlk88z7+TWUmSDr+BcA41cNqxLMsv4LkoigvopaoqqoSkluc5qQVpmn5blvVJ5uABpOv634ZxHFNNkuTg+z52XffJtm3sOA4OwxAHQYCjKMKmaWLDMLDneRhCHoH0L1VV3X/INfReKIpy438AfBao6P79ECMAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of copy in a code snippet",
        "title": "Example of copy in a code snippet",
        "src": "/static/dc21f785dc50f8670c2b42fd17f107a4/fb070/common-action-8.png",
        "srcSet": ["/static/dc21f785dc50f8670c2b42fd17f107a4/d6747/common-action-8.png 288w", "/static/dc21f785dc50f8670c2b42fd17f107a4/09548/common-action-8.png 576w", "/static/dc21f785dc50f8670c2b42fd17f107a4/fb070/common-action-8.png 1152w", "/static/dc21f785dc50f8670c2b42fd17f107a4/c3e47/common-action-8.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "edit"
    }}>{`Edit`}</h3>
    <p>{`Edit allows data or values to be changed. Edit commonly triggers a state change to the targeted object or input item.`}</p>
    <p>{`Offer edit as an option in a menu, or as a button or `}<inlineCode parentName="p">{`edit`}</inlineCode>{` icon.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.978260869565222%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAA3UlEQVQY01VQxwrFMAzL//9bKT12770XXQc/JEjpO4jEiiLLVuu6Sl3X0jSN5HnO84uiKCTLMr5pHVBV1Z8eNbxU3/fi+74EQSCWZfEehiERRRE5wzAI27bJAbibpvnqPM+TrutEnecpy7LQHeZIMU0Ta819sW0b+XmeZRiGVwcPeCl8TtOU8ZHSdV2J45ijgkMCdEdyx3FYYwV6KmighQe8FDpiB4hbluW7K9SA3hXekiRhMz0JuLZtqYMHvNTzPHJdF+Mi+jiOsu+73PdNXo8H4K754zjeMfV/eP0AgOG/smKOQLIAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of edit in a data table cell",
        "title": "Example of edit in a data table cell",
        "src": "/static/8ff559658c8e10e91c2309fba60eac47/fb070/common-action-9.png",
        "srcSet": ["/static/8ff559658c8e10e91c2309fba60eac47/d6747/common-action-9.png 288w", "/static/8ff559658c8e10e91c2309fba60eac47/09548/common-action-9.png 576w", "/static/8ff559658c8e10e91c2309fba60eac47/fb070/common-action-9.png 1152w", "/static/8ff559658c8e10e91c2309fba60eac47/c3e47/common-action-9.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "42.11956521739131%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABDUlEQVQoz6VSSWrEQAzsV8xn8s8ckicEhhznAzka7IPxxRt431ewsRtrVAIb5poxFN1dLZVKaqtpmqgoCsqyTFBVFZVlKQAfx7EgTdOLB5IkkfjznOc5QUs1TUO+75PjOGTbNgVBIAjDkFzXJcMwBKZpvtxZliU52IPzPI/quia1riuN40h930slOEQlYBgGCWrbVviu6647cLjHHvnYL8tCCkJoKYoicYRKaAcAf3JY4ea8O52ibYwDhUVwnuednQk4UMBudg6QFWdOkJXnJDzAQsjR7O5gl4fWmrZt04re/LhVzaPQLLbjDMGvf+Cb8ckid7wuZosZ8gP/qXc+/q1uLPLD7/DL83vw+D6ewigavGoPFc8AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of edit in an overflow menu",
        "title": "Example of edit in an overflow menu",
        "src": "/static/15e45f78d42afead195cf036b619267a/fb070/common-action-10.png",
        "srcSet": ["/static/15e45f78d42afead195cf036b619267a/d6747/common-action-10.png 288w", "/static/15e45f78d42afead195cf036b619267a/09548/common-action-10.png 576w", "/static/15e45f78d42afead195cf036b619267a/fb070/common-action-10.png 1152w", "/static/15e45f78d42afead195cf036b619267a/c3e47/common-action-10.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "next"
    }}>{`Next`}</h3>
    <p>{`Next advances the user to the next step in a sequence of steps, such as in a wizard.`}</p>
    <p>{`Represent next with a button with icon or a standalone `}<inlineCode parentName="p">{`forward`}</inlineCode>{` icon.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "43.30357142857143%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz62Rz4vTQBTHp8nBbZVd0D0IHjx5ENFDz16lEWFRZG3Am7gKu+qePAriakFUEEHUg7iIQm9VECyuUBAvwrbFLq2m1qRN0qQ/kmyamfy09Tkpsn/BPvjynfk++MxjHkJx3YIEWmgzN/NRAgAQVaLRaDD1ep2Jz3EmiiLT6XR2+pVKZacnyzIjCELCNE2EDl/rHVpYfbvv7DvY86oo7c3dvT3/7PmLOcMwkpZlJfP5/NydtXv7fzZ/p34IrdSDh48OvFx/M+sF0UyhUJjN5XLzzWYzpWnaTLlcRrtflfLmpe+12tVqtXqltrW1RAhZpJPx1HnHwbzrEl7SRhdEDfO6FfHq0OWroptVdCuLHTsbBOGi4zgXqTiMMYtarRboug5hGMJ4PAYHE5CkNmzbNkRRNM1U3QCRZoZhQhD4MLD/gCRr0O2qQEEQF3WBApOI/hUZDgcT3w+i0HcnAbE8tfMrNAfa2Pf9KUDrW6B2dTDMbXA9H0bYA4XeVUUBmz78H9iYAkul0vLGxufVTx8/rBS/qUuvv0bX178EN95v+pddgjMYO5w69DM9+y/XNUOu3cNcUyUZ03Y5z3NPY0w4CjtDYSepGJROp+8fPXbiyfEjB9fQqeIy4uExOj95is7Byq4vrG9i1iUOK/cxSyebukIl6oQdmCPWGdksnY6Nl0GdoY7+AYwlmO56BfxYAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of next as a button with icon",
        "title": "Example of next as a button with icon",
        "src": "/static/4c1cc25505f745ea646de669c83933c1/fb070/common-action-11.png",
        "srcSet": ["/static/4c1cc25505f745ea646de669c83933c1/d6747/common-action-11.png 288w", "/static/4c1cc25505f745ea646de669c83933c1/09548/common-action-11.png 576w", "/static/4c1cc25505f745ea646de669c83933c1/fb070/common-action-11.png 1152w", "/static/4c1cc25505f745ea646de669c83933c1/fb104/common-action-11.png 1728w", "/static/4c1cc25505f745ea646de669c83933c1/8fefe/common-action-11.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "refresh"
    }}>{`Refresh`}</h3>
    <p>{`This action reloads the view of an object, list, or data set when the displayed view has become unsynchronized with the source.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`refresh`}</inlineCode>{` icon or a button.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB6UlEQVQoz5WRSW/TUBSF/ePYwKJIVREI/g4rFkRVGBb9FUgoygJSqISqxrHjEJp4eB4yyLGTtI6Hxk4cOLz7IketxIbF0bnny/XxlSMVmw2iVYw4ToSn2R3WefHfulvn2G5LSMU6wWzCMPc9+GOGLArwZ5Ngl6+wK2KhMt/rX/PuMK/wu8whsXCHC73EpbXFD7PE118FvvRzoRafKX+7znExoMx9SCrE/J2zc/5b65r21zD8LaSzVoZHrwO8fHeLk9MIR7UUT2uZ0JM3ER5zvXif4tXHFCf1DM/rEY5rNzg+5exDimd1UoajtwnOzgtIjm2h8fkTNLWDnz0VI8eER7JNdJUr9FQZLt9xGHELPU1Bp33Jsw6H77j2njNziJtFCMm2HTSbTXS7XWiaBsuyYJomDC6504GiqAdGTjuyLEPXjQM3uQ+HOsJwToU2Go0GVFUVy4wxvrRflGUqVO4VMvHidrsNw6gKLeG6rmM+54VBEKDf74six3EwnU6FJpOJWCReZXI6gHiVK43HY0RRBClNU35qCCpeLpegnCSJEDF6a8XIF4uF4HEcP+CUi4L/KfTAYDAQbx6NRuIBYqTq6oqRe54nLqQj7nPKVCzRQN+Oykiz2UxcQE5lVEBzxSm7rvuAkfu+L678C+DBFboZqQYIAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of edit in a data table cell",
        "title": "Example of edit in a data table cell",
        "src": "/static/769cc82bfbade944fce0b7b1c5d6d513/fb070/common-action-12.png",
        "srcSet": ["/static/769cc82bfbade944fce0b7b1c5d6d513/d6747/common-action-12.png 288w", "/static/769cc82bfbade944fce0b7b1c5d6d513/09548/common-action-12.png 576w", "/static/769cc82bfbade944fce0b7b1c5d6d513/fb070/common-action-12.png 1152w", "/static/769cc82bfbade944fce0b7b1c5d6d513/c3e47/common-action-12.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      